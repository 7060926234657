import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import "./assets/styles/_index.scss"
import { useLocation } from "@reach/router"
import SocialIncons from "./SocialIcons"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const ListingIntroModule = (props) => {
  const location = useLocation();
  const PathMatch = (location?.pathname == "/why-baytify/our-social-hub/" || location?.pathname == "/why-baytify/our-social-hub");
  return (
    <section className="listing-intro-wrapper">
      <Container>
        <Row>
          <Col xl={5}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
            >
              <h1>{props.headingTitle}</h1>
            </ScrollAnimation>
          </Col>
          <Col xl={1} />
          <Col lg={6}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
            >
              {props.content && (
                <ContentModule
                  Content={
                    props.content?.data?.content
                      ? props.content?.data?.content
                      : props.content?.data?.banner_content
                  }
                />
              )}
              {PathMatch && <SocialIncons />}
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ListingIntroModule
