import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import InstagramIcon from '../../images/icons/Instagram2.svg'
import Facebook from '../../images/icons/Facebook.svg'
import LinkedInIcon from '../../images/icons/Linkedin2.svg'
import YoutubeIcon from '../../images/icons/Youtube2.svg'
import TikTok from '../../images/icons/TikTok.svg'
import X from '../../images/icons/x.svg'
import Snapchat from '../../images/icons/Snapchat.svg'

const SocialIncons = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiSiteConfig {
        instagram_link
        linkedin_link
        youtube_link
        snapchat_link
        tiktok_link
        twitter_link
        facebook_link
      }
    }
  `)

  const links = data.strapiSiteConfig;


  return (
    <div className="social-icons">
      <p>Follow us on:</p>
      <div className="icon-lists">
        <a href={links?.instagram_link && links.instagram_link} target="_blank"><img src={InstagramIcon} alt="instagram" /></a>
        <a href={links?.facebook_link && links.facebook_link} target="_blank"><img src={Facebook} alt="LinkedIn" /></a>
        <a href={links?.linkedin_link && links.linkedin_link} target="_blank"><img src={LinkedInIcon} alt="LinkedIn" /></a>
        <a href={links?.youtube_link && links.youtube_link} target="_blank"><img src={YoutubeIcon} alt="Youtube" /></a>
        <a href={links?.snapchat_link && links.snapchat_link} target="_blank"><img src={Snapchat} alt="Snapchat" /></a>
        <a href={links?.tiktok_link && links.tiktok_link} target="_blank"><img src={TikTok} alt="TikTok" /></a>
        <a href={links?.twitter_link && links.twitter_link} target="_blank"><img src={X} alt="X" /></a>
      </div>
    </div>
  )
}

export default SocialIncons;