import { Link } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import usePagination from "../../hooks/usePagination"
import InnerPagination from "../InnerPagination/InnerPagination"
import "./CommunityCards.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const CommunityCards = ({ communityData }) => {
  const imagename = "community.tile_image.small_image"
  const [search, setSearch] = useState("")
  const [selectedArea, setSelectedArea] = useState(communityData)
  const handleChange = (event) => {
    setSearch(event.target.value)
    if (event.target.value) {
      const areaGuide = communityData.filter((area, index) =>
        area.node?.title
          ?.toLowerCase()
          ?.includes(event.target.value.toLowerCase())
      )
      setCurrentPage(1)
      setSelectedArea(areaGuide)
    } else {
      setSelectedArea(communityData)
      setCurrentPage(1)
    }
  }

  // for pagination
  const itemsPerPage = 12
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedArea,
    itemsPerPage,
  })
  // for pagination

  const Image_Module = ({ data, processedImages }) => {
    return (
      <ImageModule
        ImageSrc={data.node?.tail_image}
        altText={`${data.node?.title}`}
        imagetransforms={processedImages}
        renderer="srcSet"
        imagename={imagename}
        strapi_id={data?.node?.strapi_id}
        classNames="img-card"
      />
    )
  }

  return (
    <div className="community-block">
      <div className="community-card-wrapper">
        <Container>
          <div className="search-box">
            <input
              className="form-control search"
              placeholder="Search Communities"
              onChange={handleChange}
              value={search}
            />
            <span>
              <i className="icon grey-search-icon" />
            </span>
          </div>
          <div className="community-card-sections">
            {currentItems?.map((data) => {
              let processedImages = ""
              processedImages =
                data?.node?.imagetransforms?.tail_image_Transforms ||
                JSON.stringify({})
                
              return (
                <div className="community-card-module" key={data?.strapi_id}>
                  <div className="image-section">
                    <Link to={`${data?.node?.slug}/`}>
                      <Image_Module data={data} processedImages={processedImages} />
                    </Link>
                  </div>
                  <div className="community-info">
                    <h4>
                      <Link to={`${data?.node?.slug}/`}>
                        {data?.node?.title}
                      </Link>
                    </h4>
                  </div>
                </div>
              )
            })}
          </div>
        </Container>
      </div>
      <Container>
        <InnerPagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={selectedArea.length}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </div>
  )
}

export default CommunityCards
